import * as api from '../api';

export function finishRegisteringIssuer(issuerId, body) {
  return api.patch(`issuer/${issuerId}`, body);
}

export function getIssuerProfile(issuerId) {
  return api.get(`issuer/${issuerId}`);
}

export function getCompleteIssuerProfile(issuerId) {
  return api.get(`issuer/complete/${issuerId}`);
}

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('issuer/logo', formData);
}

export function downloadImage(id) {
  return api.get(`issuer/logo/download/${id}`);
}

export function uploadSocialContract(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('issuer/socialContract', formData);
}

export function downloadSocialContract(id) {
  return api.get(`issuer/socialContract/download/${id}`);
}

export function uploadAdditionalDocs(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('issuer/additionalDocs', formData);
}

export function downloadAdditionalDocs(id) {
  return api.get(`issuer/additionalDocs/download/${id}`);
}

export function getIssuerLoginProfile(id) {
  return api.get(`issuerLogin/${id}`);
}

export function download(url0rId) {
  return api.get(`upload/download/${url0rId}`);
}

export function uploadIssuerUserImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('issuerLogin/image', formData);
}

export function downloadIssuerUserImage(id) {
  return api.get(`issuerLogin/image/download/${id}`);
}

export function updateUserInfo(body) {
  return api.patch(`issuerLogin`, body);
}
