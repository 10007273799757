import * as api from '../api';

export function getIssuerWithdraws(params) {
  return api.get('issuerWithdraw', params);
}

export function getWithdrawDetails(issuerWithdrawId) {
  return api.get(`issuerWithdraw/${issuerWithdrawId}`);
}

export function generateToken(body) {
  return api.post(`issuerWithdrawToken`, body);
}

export function validateToken(params) {
  return api.get('issuerWithdrawToken/validateToken', params);
}

export function requestWithdraw(issuerWithdrawId, params) {
  return api.get(`issuerWithdraw/request/${issuerWithdrawId}`, params);
}

export function listDeposits(params) {
  return api.get('issuerDeposit', params);
}

export function getDepositDetails(depositId) {
  return api.get(`issuerDeposit/${depositId}`);
}

export function confirmDeposit(params) {
  return api.patch('issuerDeposit', params);
}

export function getIssuerWallet(issuerId) {
  return api.get(`issuer/balanceAndPayableAmount/${issuerId}`);
}
