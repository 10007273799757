import axios from 'axios';
import * as api from '../api';

export function getBankAccountInfo() {
  return api.get('issuerBankData');
}

export function registerBankData(body) {
  return api.post('issuerBankData', body);
}

export function editBankData(body) {
  return api.put('issuerBankData', body);
}

export function getAllBanks() {
  return axios.get('https://brasilapi.com.br/api/banks/v1');
}
